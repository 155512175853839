import {Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged} from 'rxjs';


import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import * as auth from 'firebase/auth';
import {TransitionService} from '@services/transition.service';
import {Role, User, UserQuery, UserService} from '@services/entity/user';
import {StoreService} from '@services/store.service';
import {deepCopy} from '@utils/utils-function';
import UserCredential = auth.UserCredential;

@Injectable({
    providedIn: 'root'
})
export class AuthentificationService {
    userConnecte: User;
    connecte$ = new BehaviorSubject<boolean>(false);

    user$ = new BehaviorSubject<User>(null);


    constructor(private storeService: StoreService,
                private userService: UserService,
                private userQuery: UserQuery,
                private transitionService: TransitionService,
                private router: Router) {
        this.userQuery.select().subscribe(rep => {
            console.log('statut auth :  ' + JSON.stringify(rep));
        });
        this.userService.sync().subscribe();
    }


    async init(): Promise<boolean> {
        // Cas du mode offline
        /**
         * Attention, il passe la première fois avec rep.uid null puis rep.uid='undefined" s'il n'est pas connecté.
         * Il y a aussi un bug louche ou il passe avec un profil "test":'test" et enfin un autre passage à cause du setActive...
         */
        return new Promise((resolve, reject) => {

            this.userQuery.select().pipe(filter((rep: any) => !rep.loading && rep.uid !== null), distinctUntilChanged((previous, current) => {
                return previous.uid === current.uid && previous.profile?.email === current.profile?.email;
            })).subscribe(async (rep: any) => {
                if (typeof rep.uid !== 'undefined' && rep.profile?.email) {
                    console.log('AUTH AUTOMATIQUE USER : ' + rep.profile.email + ' UID=' + rep.uid);
                    /*    console.log('waiting.....');
                        await firstValueFrom(interval(5000));
                        console.log('waiting fini.....'); */
                    await this.onLoginSuccess(rep.profile);
                    resolve(true);
                } else {
                    // Pas d'authentification, je renvoie tjs sur login
                    //   this.router.navigate(['/walkthrough']);
                    resolve(false);
                }
            });
        });

    }

    doLoginByEmail(email: string, password: string): Promise<UserCredential> {
        return this.userService.signin(email, password);
    }

    logout(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.userService.signOut().then(rep => {
                this.userConnecte = null;
                this.user$.next(null);
                this.connecte$.next(false);
                this.transitionService.purge();
                this.storeService.purge();
                resolve();
            }, err => {
                console.error('Souci logout : ' + err);
                reject(err);
            });
        });

    }

    async onLoginSuccess(user: User, redirection?: string) {
        try {
            const monUser = deepCopy<User>(user);
            console.log('Login succes OK ' + monUser.email + ' - ID=' + monUser.id);


            // Je broadcast le user
            this.userConnecte = monUser;
            this.user$.next(monUser);
            this.connecte$.next(true);


            // Chargement du store
            /* await */
            this.storeService.initialisation(monUser.id.toString());

            if (monUser.roles?.includes(Role.administrateur)) {
                this.router.navigate(['exercices']);
                return;
            }
            if (monUser.roles?.includes(Role.testeur)) {
                this.router.navigate(['demo-jeu']);
                return;
            }
            this.router.navigate(['auth/forbidden']);
        } catch
            (e) {
            console.error(e);
            throw e;
        }


    }


}
