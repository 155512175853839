import {Injectable} from '@angular/core';
import {UserService, UserStore} from '@services/entity/user';

import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {EntityStore, ID} from '@datorama/akita';
import {TransitionService} from '@services/transition.service';
import {CollectionService} from 'akita-ng-fire';

import {NotificationService} from '@services/notification.service';
import {ExerciceService, ExerciceStore} from '@services/entity/exercice';
import {PodSimulatorService} from '@services/entity/pod-simulator';
import {CognitifQuery, CognitifService, CognitifStore} from '@services/entity/cognitif';
import {PathologieQuery, PathologieService, PathologieStore} from '@services/entity/pathologie';
import { VersionService } from '@services/entity/versions';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    storesLoaded$ = new BehaviorSubject<Array<string>>(new Array<string>());
    storeReady$ = new BehaviorSubject<boolean>(false);
    entitiesName = new Array<string>();
    saving$ = new BehaviorSubject<boolean>(false);

    constructor(
        private exerciceService: ExerciceService,
        private userService: UserService,
        private pathologieService: PathologieService,
        private pathologieQuery: PathologieQuery,
        private pathologieStore: PathologieStore,
        private cognitifService: CognitifService,
        private versionService: VersionService,
        private cognitifQuery: CognitifQuery,
        private cognitifStore: CognitifStore,
        private exerciceStore: ExerciceStore,
        private userStore: UserStore,
        private podSimulatorService: PodSimulatorService,
        private notificationService: NotificationService,
        private transitionService: TransitionService) {
    }

    initialisation(idUser: ID) {
        console.log('Initialisation du store... USER=' + idUser);
        this.userStore.setActive(idUser + '');
        this.transitionService.init('' + idUser);
        this.synchroniseCollection('exercice', this.exerciceService);
        this.synchroniseCollection('pod-simulator', this.podSimulatorService);
        this.synchroniseCollection('pathologie', this.pathologieService);
        this.synchroniseCollection('cognitif', this.cognitifService);
        this.synchroniseCollection('versions', this.versionService);
        this.storesLoaded$.subscribe(storeLoaded => {
            let tousOK = true;
            this.entitiesName.forEach(nom => {
                if (!storeLoaded.includes(nom)) {
                    tousOK = false;
                }
            });
            if (tousOK) {
                this.storeReady$.next(true);
            }
        });
        console.log('Initialisation du store OK');
    }

    waitLoadFromFirebase(storeName: string): Observable<boolean> {
        return this.storesLoaded$.pipe(map(storeLoaded => storeLoaded.indexOf(storeName) >= 0), filter(present => present));
    }

    purge() {
        console.log('=== PURGE DES STORES ===');
        this.userStore.reset();
        this.exerciceStore.reset();
    }

    purgeStore(store: EntityStore) {
        console.log('RESET de ' + store.storeName);
        store.reset();
        this.entitiesName = this.entitiesName.filter(nom => nom !== store.storeName);
        let tab = this.storesLoaded$.getValue();
        tab = tab.filter(nom => nom !== store.storeName);
        this.storesLoaded$.next(tab);
    }

    stopSaving() {
        this.saving$.next(false);
    }


    public synchroniseCollection(nom: string, service: CollectionService<any>) {
        this.entitiesName.push(nom);
        service.syncCollection().subscribe(rep => {
            if (this.storesLoaded$.getValue().indexOf(nom) === -1) {
                this.storesLoaded$.getValue().push(nom);
                this.storesLoaded$.next(this.storesLoaded$.getValue());
            }
        }, err => {
            console.log('Erreur chargement ' + nom);
            console.error(err);
            this.notificationService.openSnackBarErreur('Souci technique sur le chargement des données!');
        });
    }
}
