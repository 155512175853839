import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MediaMatcher} from '@angular/cdk/layout';
import {NGXLogger} from 'ngx-logger';
import {throwIfAlreadyLoaded} from './guards/module-import.guard';
import {GlobalErrorHandler} from 'src/app/core/services/global-error.handler';
import {RoleGuard} from './guards/role.guard';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [],
    providers: [

        RoleGuard,
        MediaMatcher,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        { provide: NGXLogger, useClass: NGXLogger },
        { provide: 'LOCALSTORAGE', useValue: window.localStorage }
    ],
    exports: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throwIfAlreadyLoaded(parentModule, 'CoreModule');
        }
    }
}
