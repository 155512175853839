import {ErrorHandler, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {NotificationService} from '@services/notification.service';
import {AuthentificationService} from '@services/auth.service';
import {environment} from '@environment/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    lastLog: Date = null;

    constructor(private notificationService: NotificationService,
                private authentificationService: AuthentificationService,
                private logger: NGXLogger) {
    }

    handleError(error: Error) {
        // Obtain dependencies at the time of the error
        // This is because the GlobalErrorHandler is registered first
        // which prevents constructor dependency injection
        console.log('ERREUR ' + error?.message);

        if (error?.message.startsWith('Missing or insufficient permissions')) {
            console.log('Droit FIREBASE KO');
        }
        const err = {
            message: error.message ? error.message : error.toString(),
            stack: error.stack ? error.stack : ''
        };


        // Log  the error
        if (this.lastLog === null) {
            this.logger.error(err);
            if (environment.envName === 'DEV') {
                //  Re-throw the error
                throw error;
            } else {
                this.notificationService.openSnackBarErreur('Une erreur technique est survenue!!! Les éléments ont été enregistrés ' +
                    'pour analyse par notre service technique');

            }
        } else {
            if (new Date().getTime() > this.lastLog.getTime() + 60000) {
                this.logger.error(err);
                if (environment.envName === 'DEV') {
                    //  Re-throw the error
                    throw error;
                } else {
                    this.notificationService.openSnackBarErreur('Une erreur technique est survenue!!! Les éléments ont été enregistrés ' +
                        'pour analyse par notre service technique');

                }
            }
        }
        this.lastLog = new Date();

        // Re-throw the error
        // throw error;
    }
}
