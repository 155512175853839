import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Exercice} from './exercice.model';
import {CollectionState} from 'akita-ng-fire';

export type ExerciceState = CollectionState<Exercice>

@Injectable({providedIn: 'root'})
@StoreConfig({
    name: 'exercice',
    idKey: 'id',
    resettable: true
})
export class ExerciceStore extends EntityStore<ExerciceState> {

    constructor() {
        super();
    }

}
