import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ExerciceState, ExerciceStore} from './exercice.store';

@Injectable({providedIn: 'root'})
export class ExerciceQuery extends QueryEntity<ExerciceState> {

    constructor(protected store: ExerciceStore) {
        super(store);
    }

}
