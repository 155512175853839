import {ID} from '@datorama/akita';
import * as firestore from 'firebase/firestore';
import Timestamp = firestore.Timestamp;

export interface Log {
    id: ID;
    date: Timestamp;
    message: string;
    idUser: string;
    surnom: string;
    version: string;
    idExercice?: string;
    contenuCache?: string;
    titre?: string; // Titre de l'"exrcice
    traite?: boolean;
}

export function createLog(params: Partial<Log>) {
    return {
        date: Timestamp.now()
    } as Log;
}
