import { Injectable } from '@angular/core';
import { VersionState, VersionStore } from './version.store';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';

@Injectable({providedIn: 'root'})
@CollectionConfig({path: 'versions'})
export class VersionService extends CollectionService<VersionState> {

    constructor(protected store: VersionStore) {
        super(store);
    }
}
