import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CustomMaterialModule} from '../custom-material/custom-material.module';
import {LimitToPipe} from './pipes/limit-to.pipe';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {
    ContentPlaceholderAnimationComponent
} from './content-placeholder-animation/content-placeholder-animation.component';
import {LocalDatePipe} from './pipes/local-date.pipe';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {AlertDialogComponent} from 'src/app/shared/alert-dialog/alert-dialog.component';
import {ImgFirebaseComponent} from '@shared/img-firebase/img-firebase.component';
import {NgOptimizedImage} from '@angular/common';
import {DialogBigPictureComponent} from './img-firebase/dialog-big-picture/dialog-big-picture.component';
import {NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';


@NgModule({
    imports: [
        RouterModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgOptimizedImage,
        NgxMaskDirective, NgxMaskPipe,
    ],
    declarations: [
        ConfirmDialogComponent,
        AlertDialogComponent,
        ContentPlaceholderAnimationComponent,
        ImgFirebaseComponent,
        LimitToPipe,
        LocalDatePipe,
        YesNoPipe,
        DialogBigPictureComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CustomMaterialModule,
        LimitToPipe,
        ConfirmDialogComponent,
        ImgFirebaseComponent,
        AlertDialogComponent,
        ContentPlaceholderAnimationComponent,
        LocalDatePipe,
        YesNoPipe
    ]
})
export class SharedModule {
}
