import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Log} from './log.model';
import {CollectionState} from 'akita-ng-fire';

export type LogState = CollectionState<Log>

@Injectable({providedIn: 'root'})
@StoreConfig({
    name: 'log',
    idKey: 'id',
    resettable: true
})
export class LogStore extends EntityStore<LogState> {

    constructor() {
        super();
    }

}
