import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';


import {AuthentificationService} from '@services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppInitialisationService {

    constructor(private authService: AuthentificationService) {
    }

    async init() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<void>(async (resolve, reject) => {
            //   console.log('INITIALISATION  ' + window.location.hostname);
            // Souci de compatibilité bien emmerdant avec RXJS 7
            if (!environment.production) {
                console.log('* Firebase env : ' + environment.firebase.projectId + ' *');
                console.log('**************************');
            }
            console.log('=======> AppInitialisation => init du authService');
            this.authService.init();
            resolve();

        });
    }


}
