import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {Version} from './version.model';
import {CollectionState} from 'akita-ng-fire';

export interface VersionState extends CollectionState<Version> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({
    name: 'version',
    idKey: 'id',
    resettable: true
})
export class VersionStore extends EntityStore<VersionState> {

    constructor() {
        super();
    }

}
