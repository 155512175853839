import {Injectable} from '@angular/core';
import {PodSimulatorState, PodSimulatorStore} from './pod-simulator.store';
import {CollectionConfig, CollectionService} from 'akita-ng-fire';
import {UserQuery} from '@services/entity/user';
import {TransitionService} from '@services/transition.service';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users/:idUser/pods-simulator' })
export class PodSimulatorService extends CollectionService<PodSimulatorState> {

    constructor(protected store: PodSimulatorStore,
                private transitionService: TransitionService,
                private userQuery: UserQuery) {
        super(store);
    }

    get path() {
        const userId = this.transitionService.getIdUserSimulator();
        return 'users/' + userId + '/pods-simulator';
    }

}
