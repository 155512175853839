import {Injectable} from '@angular/core';
import {NGXLoggerServerService} from 'ngx-logger';
import {INGXLoggerMetadata} from 'ngx-logger/lib/metadata/imetadata';
import {INGXLoggerConfig} from 'ngx-logger/lib/config/iconfig';
import {createLog, LogService} from './entity/log';
import {AuthentificationService} from '@services/auth.service';
import {environment} from '@environment/environment';

@Injectable({
    providedIn: 'root'
})
export class LogServerService extends NGXLoggerServerService {
    constructor(private logService: LogService,
                private authService: AuthentificationService) {
        super(null, null);
    }

    /** Write the content sent to the log function to the sessionStorage */
    public sendToServer(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {
        console.log('SEND TO SERVER' + metadata.message);
        const log = createLog({});
        //    log.metadata = metadata;
        log.message = metadata.message;
        log.version = 'BACKOFFICE WEB ' + environment.versions.app + ' - ' + environment.envName;
        const user = this.authService.userConnecte;
        if (user !== null && typeof user !== 'undefined') {
            log.idUser = '' + user.id;
            log.surnom = user.email;
        } else {
            log.idUser = 'UNCONNECTED';
            log.surnom = 'UNCONNECTED';
        }
        console.log('LOG = ', log.date.toJSON());
        if (!environment.production) {
            return;
        }
        this.logService.add(log).then(rep => {
            console.log('Log saved');
        }, err => {
            console.error('Souci enregistrement log');
        });
    }
}
