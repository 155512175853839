import {LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatMomentDateModule} from '@angular/material-moment-adapter';

import {MatBadgeModule} from '@angular/material/badge';

import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';

import {MatSidenavModule} from '@angular/material/sidenav';

import {MatSortModule} from '@angular/material/sort';


import {MatToolbarModule} from '@angular/material/toolbar';

import {SelectCheckAllComponent} from './select-check-all/select-check-all.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PageTitleComponent} from 'src/app/custom-material/page-title/page-title.component';
import {FlexModule} from '@angular/flex-layout';
import {DialogConfirmationComponent} from './dialog-confirmation/dialog-confirmation.component';

import {IConfig, NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';

import {SavingButtonComponent} from './saving-button/saving-button.component';
import {HoverClassDirectiveDirective} from './directives/hover-class-directive.directive';

import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {IconPack} from '@fortawesome/fontawesome-svg-core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatGridListModule} from '@angular/material/grid-list';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
const maskConfig: Partial<IConfig> = {
    showMaskTyped: true,
    dropSpecialCharacters: false
};

@NgModule({
    imports: [
        CommonModule,
        MatMomentDateModule,
        MatSidenavModule, MatIconModule, MatToolbarModule, MatButtonModule,
        MatListModule, MatCardModule, MatProgressBarModule, MatInputModule,
        MatSnackBarModule, MatProgressSpinnerModule, MatDatepickerModule,
        MatAutocompleteModule, MatTableModule, MatDialogModule, MatTabsModule,
        MatTooltipModule, MatSelectModule, MatPaginatorModule, MatChipsModule,
        MatButtonToggleModule, MatSlideToggleModule, MatBadgeModule, MatCheckboxModule,
        MatExpansionModule, DragDropModule, MatSortModule, FlexModule, MatFormFieldModule,
        NgxMaskDirective, NgxMaskPipe, MatRadioModule,
        NgxMatSelectSearchModule, FontAwesomeModule, MatGridListModule
    ],
    exports: [
        CommonModule,
        MatSidenavModule, MatIconModule, MatToolbarModule, MatButtonModule,
        MatListModule, MatCardModule, MatProgressBarModule, MatInputModule,
        MatSnackBarModule, MatMenuModule, MatProgressSpinnerModule, MatDatepickerModule,
        MatAutocompleteModule, MatTableModule, MatDialogModule, MatTabsModule,
        MatTooltipModule, MatSelectModule, MatPaginatorModule, MatChipsModule,
        MatButtonToggleModule, MatSlideToggleModule, MatBadgeModule, MatCheckboxModule,
        MatExpansionModule, SelectCheckAllComponent, DragDropModule, MatSortModule, PageTitleComponent,
        NgxMaskDirective, NgxMaskPipe, MatRadioModule, SavingButtonComponent, HoverClassDirectiveDirective,
        NgxMatSelectSearchModule, FontAwesomeModule, MatGridListModule
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_FORMATS
        },
        { provide: LOCALE_ID, useValue: 'en-gb' }
    ],
    declarations: [SelectCheckAllComponent,
        PageTitleComponent,
        DialogConfirmationComponent,
        SavingButtonComponent,
        HoverClassDirectiveDirective,]
})
export class CustomMaterialModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas as IconPack, fab as IconPack, far as IconPack);
    }

    static forRoot(): ModuleWithProviders<CustomMaterialModule> {
        return {
            ngModule: CustomMaterialModule,
            providers: []
        };
    }
}
