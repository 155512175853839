import {NgxLoggerLevel} from 'ngx-logger';
import {env} from './env';

export const environment = {
    production: true,
    hmr: false,
    logLevel: 7,
    serverLogLevel: NgxLoggerLevel.OFF,
    envName: 'PROD',
    appName: env.appName,
    versions: env.versions,
    firebase: {
        apiKey: 'AIzaSyCJPhqKnrbmyd6Leq9g_kw6zmGZmuP5p4w',
        authDomain: 'reflexcare-prod.firebaseapp.com',
        projectId: 'reflexcare-prod',
        storageBucket: 'reflexcare-prod.appspot.com',
        messagingSenderId: '129087141579',
        appId: '1:129087141579:web:81795257d8b7e67c4d4d4e',
        measurementId: 'G-QSC01FQ9EX'
    },
    api: {
        reflexcare: {
            url: 'https://us-central1-reflexcare-prod.cloudfunctions.net/api',
            user: 'client',
            password: '!7xxZiL4vbVba&e6bgKO'
        },
    },
    google: {
        appCheckKey: '6LdA0mkiAAAAANzVaLKTQQbk3yoSvJZAnt2OcvOI'
    },
};
