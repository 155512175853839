import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Cognitif} from './cognitif.model';

export interface CognitifState extends EntityState<Cognitif> {
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'cognitif',
    idKey: 'id',
    resettable: true
})
export class CognitifStore extends EntityStore<CognitifState> {

    constructor() {
        super();
    }

}
