import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthentificationService} from '@services/auth.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '@environment/environment';

@Injectable()
export class HttpInterceptorApiReflexcare implements HttpInterceptor {

    constructor(private authentificationService: AuthentificationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes(environment.api.reflexcare.url)) {
            const user = this.authentificationService.userConnecte;
            // @ts-ignore
            request = request.clone({ setHeaders: { 'x-custom-api-id': user?.idUserFirebase }, });
        }
        return next.handle(request);
    }
}
