import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {CoreModule} from './core/core.module';
import {SharedModule} from '@shared/shared.module';
import {CustomMaterialModule} from './custom-material/custom-material.module';
import {AppRoutingModule} from './app-routing.module';
import {LoggerModule, TOKEN_LOGGER_SERVER_SERVICE} from 'ngx-logger';
import {environment} from '@environment/environment';
import {AppInitialisationService} from '@services/app-initialisation.service';
import {AppComponent} from './app.component';
//import {CalendarModule, DateAdapter} from 'angular-calendar';
//import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {LogServerService} from '@services/log-server.service';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpInterceptorApiReflexcare} from './core/guards/http-interceptor-api-reflexcare';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {initializeAppCheck, provideAppCheck, ReCaptchaV3Provider} from '@angular/fire/app-check';

export function initializeApp1(appInitService: AppInitialisationService) {
    return (): Promise<any> => appInitService.init();
}

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

if (location.hostname === 'localhost' && environment.envName === 'DEV') {
    // @ts-ignore
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
} else {
    // @ts-ignore
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
}

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        CustomMaterialModule.forRoot(),
        AppRoutingModule,
        LoggerModule.forRoot({
            serverLoggingUrl: `http://my-api/logs`,
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel
        }, {
            serverProvider: {
                provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: LogServerService
            }
        }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
        provideAppCheck(() => initializeAppCheck(undefined, {
            isTokenAutoRefreshEnabled: true,
            provider: new ReCaptchaV3Provider(environment.google.appCheckKey)
        })),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitialisationService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorApiReflexcare,
            multi: true,
        },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
