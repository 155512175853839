import {ID} from '@datorama/akita';

export interface Pathologie {
    id: ID;
    nom: string;
    description?: string;
    prive?: boolean;
}

export function createPathologie(params: Partial<Pathologie>) {
    const patho = { prive: false } as Pathologie;
    Object.keys(params).forEach(attr => patho[attr] = params[attr]);
    return patho as Pathologie;
}
