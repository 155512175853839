import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthentificationService} from '@services/auth.service';
import {NotificationService} from '@services/notification.service';
import {Role} from '@services/entity/user';

@Injectable()
export class RoleGuard  {
    constructor(
        private router: Router,
        private notificationService: NotificationService,
        private authService: AuthentificationService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authService.userConnecte;
        const rolesNecessaire = route.data.roles as Array<Role>;
        console.log('ROLE GUARD ' + rolesNecessaire);
        if (user) {
            let autorise = false;
            rolesNecessaire.forEach((role) => {
                if (user.roles?.includes(role)) {
                    autorise = true;
                }
            });
            if (!autorise) {
                this.notificationService.openSnackBarErreur('Vous n\'etes pas autorisé à accéder à cette page!');
            }
            return autorise;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
