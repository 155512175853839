import {Injectable} from '@angular/core';
import {UserState, UserStore} from './user.store';
import {CollectionConfig, FireAuthService} from 'akita-ng-fire';
import firebase from 'firebase/compat';
import {User} from '@services/entity/user/user.model';
import {from, Observable} from 'rxjs';
import {User as UserAuth} from '@firebase/auth';
import {collection, getDocs, query, where} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';

import UserFirebase = firebase.User;

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users' })
export class UserService extends FireAuthService<UserState> {
    constructor(protected store: UserStore) {
        super(store);

    }

    selectProfile(user: UserAuth): Observable<UserState['profile']> {
        /*
        Petit hack ici car en standard, la lib va chercher dans /users le user dont l'uid firebase == uid de l'authentification
        Comme dans la base initiale, j'ai des /users avec des id aléatoire, je dois chercher le /users via le champ idUserFirebase
         */
        const usersRef = collection(this.db, 'users');
        const req = query(usersRef, where('idUserFirebase', '==', user.uid));
        return from(getDocs(req)).pipe(map(users => {
            if (!users.empty && users.docs.length > 0) {
                console.log('usr', users.docs[0].data());
                return users.docs[0].data() as UserState['profile'];
            } else {
                throw new Error('Impossible de trouver le user uid = ' + user.uid + ' en condition ');
            }
        }));

    }

    createProfile(user: UserFirebase): UserState['profile'] {
        const newUser = {
            id: user.uid,
            uid: user.uid,
            idUserFirebase: user.uid,
            email: user.email,
            //displayName: user.displayName || user.email,
            urlPhoto: user.photoURL || './assets/sample-images/avatar.png',
            nom: user.displayName || user.email,
            prenom: '',
            emailVerified: false,
            verified: false,
            metier: null,
            specialisation: null,
            ville: null,
            roles: []
        } as User;
        return newUser;
    }
}
