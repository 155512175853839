import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {VersionState, VersionStore} from './version.store';

@Injectable({providedIn: 'root'})
export class VersionQuery extends QueryEntity<VersionState> {

    constructor(protected store: VersionStore) {
        super(store);
    }

}
