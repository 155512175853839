import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PathologieState, PathologieStore} from './pathologie.store';

@Injectable({ providedIn: 'root' })
export class PathologieQuery extends QueryEntity<PathologieState> {

    constructor(protected store: PathologieStore) {
        super(store);
    }

}
