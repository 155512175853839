import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';

@Injectable({
    providedIn: 'root'
})
export class TransitionService {
    private idUser: string;

    private cache: Map<string, any>;
    private idUserSimulator: string;

    constructor() {
        this.cache = new Map<string, any>();
    }

    get(nom: string): any {
        return this.cache.get(nom);
    }

    put(nom: string, objet: any) {
        return this.cache.set(nom, objet);
    }


    getIdUser(): string {
        return this.idUser;
    }

    purge() {
        this.cache = new Map<string, any>();
    }

    init(iduser: string) {
        this.idUser = iduser;
    }

    setIdUserSimulator(id: string | ID) {
        this.idUserSimulator = id + '';
    }

    getIdUserSimulator() {
        return this.idUserSimulator;
    }
}
