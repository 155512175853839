import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {User} from './user.model';
import {FireAuthState, initialAuthState} from 'akita-ng-fire';

export interface UserState extends FireAuthState<User> {
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'auth',
    resettable: true
})
export class UserStore extends EntityStore<UserState> {

    constructor() {
        super(initialAuthState);
    }

}
