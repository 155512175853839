import {ID} from '@datorama/akita';

export interface Cognitif {
    id: ID;
    nom: string;
    description?: string;
    prive?: boolean;
}

export function createCognitif(params: Partial<Cognitif>) {
    const patho = {
        prive: false
    } as Cognitif;

    Object.keys(params).forEach(attr => patho[attr] = params[attr]);
    return patho as Cognitif;
}
