<div [class.example-is-mobile]="mobileQuery.matches" class="navbar-container">

    <!-- Top navbar -->
    <mat-toolbar class="navbar" color="primary">

        <button (click)="snav.toggle()" *ngIf="mobileQuery.matches" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>


        <a [routerLink]="['/']" class="navbar-brand" matTooltip="Home">
            <p>ReflexTime <b>{{env}}</b></p>
        </a>
        <div class="central-menu">
            <div *ngIf="user?.roles?.includes(enumRole.testeur)" class="menu">
                <a [routerLink]="['/demo-jeu']" routerLinkActive="active">
                    <button [ngClass]="{ 'selected' : menuEnCours?.startsWith('/demo-jeu') }"
                            mat-raised-button>
                        <mat-icon>sports_esports</mat-icon>
                        Démo Exo
                    </button>
                </a>
            </div>
            <div *ngIf="user?.roles?.includes(enumRole.administrateur)" class="menu">
                <a [routerLink]="['/exercices']" routerLinkActive="active">
                    <button [ngClass]="{ 'selected' : menuEnCours?.startsWith('/exercices') }"
                            mat-raised-button>
                        <mat-icon>fitness_center</mat-icon>
                        Exercices
                    </button>
                </a>
            </div>
            <div *ngIf="user?.roles?.includes(enumRole.administrateur)" class="menu">
                <a [routerLink]="['/administration']" routerLinkActive="active">
                    <button [ngClass]="{ 'selected' : menuEnCours?.startsWith('/administration') }"
                            mat-raised-button>
                        <mat-icon>supervisor_account</mat-icon>
                        Gestion droits
                    </button>
                </a>
            </div>
            <div *ngIf="user?.roles?.includes(enumRole.administrateur)" class="menu">
                <a [routerLink]="['/administration/divers']" routerLinkActive="active">
                    <button [ngClass]="{ 'selected' : menuEnCours?.startsWith('/administration/divers') }"
                            mat-raised-button>
                        <mat-icon>admin_panel_settings</mat-icon>
                        Aministration
                    </button>
                </a>
            </div>
        </div>


        <button [matMenuTriggerFor]="notificationMenu" mat-icon-button>
            <mat-icon matBadge="2" matBadgeColor="accent">notifications</mat-icon>
        </button>
        <mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="above">

            <a mat-menu-item>
                <span>You have new tasks</span>
            </a>
            <a mat-menu-item>
                <span>You have a new message</span>
            </a>

        </mat-menu>


        <button [matMenuTriggerFor]="userMenu" mat-button>
            <mat-icon>person</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="above">

            <a [routerLink]="['/account/profile']" mat-menu-item>
                <mat-icon>person</mat-icon>
                <span>Account</span>
            </a>

            <a (click)="logout()" *ngIf="user" mat-menu-item>
                <mat-icon>exit_to_app</mat-icon>
                <span>Log out</span>
            </a>

        </mat-menu>

    </mat-toolbar>

    <mat-sidenav-container class="navbar-sidenav-container">
        <!-- Side nav -->
        <mat-sidenav #snav [fixedInViewport]="mobileQuery.matches"
                     [mode]="mobileQuery.matches ? 'over' : 'side'"
                     [opened]="!mobileQuery.matches" class="sidenav" fixedTopGap="56">

            <mat-nav-list>
                <h3 mat-subheader>Home</h3>

                <a [routerLink]="['/dashboard']" mat-list-item routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        dashboard
                    </mat-icon>
                    <p mat-line> Dashboard </p>
                </a>

                <a [routerLink]="['/chauffeurs']" mat-list-item routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        people
                    </mat-icon>
                    <p mat-line> Chauffeurs </p>
                </a>


                <a [routerLink]="['/account/profile']" mat-list-item>
                    <mat-icon mat-list-icon>person</mat-icon>
                    <p mat-line> Account </p>
                </a>

                <a [routerLink]="['/auth/login']" mat-list-item>
                    <mat-icon mat-list-icon>exit_to_app</mat-icon>
                    <p mat-line> Log out </p>
                </a>

                <mat-divider></mat-divider>

                <h3 mat-subheader>Resources</h3>

                <a [routerLink]="['/demo-layout']" mat-list-item routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        apps
                    </mat-icon>
                    <p mat-line> Layout </p>
                </a>
                <a [routerLink]="['/typography']" mat-list-item routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        font_download
                    </mat-icon>
                    <p mat-line> Typography </p>
                </a>

                <a href="https://material.angular.io/components/categories" mat-list-item target="_blank">
                    <mat-icon mat-list-icon>
                        link
                    </mat-icon>
                    <p mat-line> Material Components </p>
                </a>

                <a [routerLink]="['/about']" id="push-bottom" mat-list-item routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        info_outline
                    </mat-icon>
                    <p mat-line> About </p>
                </a>
            </mat-nav-list>

        </mat-sidenav>

        <!-- Main content -->
        <mat-sidenav-content class="sidenav-content">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>

</div>
