import {Injectable} from '@angular/core';
import {PathologieState, PathologieStore} from './pathologie.store';
import {CollectionConfig, CollectionService} from 'akita-ng-fire';
import {TransitionService} from '@services/transition.service';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'pathologies' })
export class PathologieService extends CollectionService<PathologieState> {

    constructor(private pathologieStore: PathologieStore, private transitionService: TransitionService) {
        super(pathologieStore);
    }

    /*
        get path() {
            const idUser = this.transitionService.getIdUser();
            return 'users/' + idUser + '/pathologies';
        }
    */
}
