import {Injectable} from '@angular/core';
import {CollectionConfig, CollectionService} from 'akita-ng-fire';
import {ExerciceState, ExerciceStore} from './exercice.store';

@Injectable({providedIn: 'root'})
@CollectionConfig({path: 'exercices'})
export class ExerciceService extends CollectionService<ExerciceState> {

    constructor(protected store: ExerciceStore) {
        super(store);
    }
}

