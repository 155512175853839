import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CognitifState, CognitifStore} from './cognitif.store';

@Injectable({ providedIn: 'root' })
export class CognitifQuery extends QueryEntity<CognitifState> {

    constructor(protected store: CognitifStore) {
        super(store);
    }

}
