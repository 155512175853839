import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar,) {
    }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000
        });
    }

    public openSnackBarConfirmation(message: string) {
        this.snackBar.open(message, '', {
            duration: 2000,
            panelClass: ['confirm-snackbar']
        });
    }


    public openSnackBarErreur(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000,
            panelClass: ['erreur-snackbar']
        });
    }
}
