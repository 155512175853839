import {ID} from '@datorama/akita';
import {ColorPod, Pod} from '@services/entity/pod';
import * as firestore from 'firebase/firestore';
import Timestamp = firestore.Timestamp;

// eslint-disable-next-line no-shadow
export enum ExerciceDifficulte {
    Facile = 'Facile',
    Normal = 'Normal',
    Difficile = 'Difficile'
}

// eslint-disable-next-line no-shadow
export enum TypeArticulation {
    Epaule = 'Epaule',
    Coude = 'Coude',
    Poignet = 'Poignet',
    Genoux = 'Genoux',
    Cheville = 'Cheville',
    Hanche = 'Hanche',
    Cuisse = 'Cuisse',
    Nuque = 'Nuque',

    Cervicales = 'Cervicales',
    Rachis = 'Rachis',
    Lombaires = 'Lombaires',
    Fesses = 'Fesses',
    Mollet = 'Mollet',
}

// eslint-disable-next-line no-shadow
export enum TypeHit {
    toucher = 'toucher', temps = 'temps'
}

export enum TypeExercice {
    aleatoire = 'Aléatoire',
    retourBase = 'Retour Base',
    parcours = 'Parcours',
    manuel = 'Manuel',
    multijoueurs = 'Multi-Joueurs',
    course = 'Course',
    memoire = 'Mémoire'
}

export enum ModeCreation {
    rapide = 'Rapide',
    avance = 'Avancé'
}

export enum TypePositionnement {
    mur = 'Mur',
    sol = 'Sol',
    table = 'Table',
}

export enum SousTypeExerciceMultiJoueur {
    aleatoire = 'Aléatoire',
    // retourBase = 'Retour à la base'
}

export enum TypeCouleurOuSon {
    couleur = 'couleur', son = 'son'
}

export enum TypeDuree {
    temps = 'temps', toucher = 'toucher'
}

export enum Language {
    fr = 'Français',
    en = 'English',
    es = 'Spanish',
    de = 'Deutch',
    it = 'Italian'
}

export interface Exercice {
    id: ID;
    //articulation: TypeArticulation;
    //idPathologie: ID | null,
    // idCognitif: ID | null,
    articulations: Array<TypeArticulation>;
    idPathologies: Array<ID>;
    idCognitifs: Array<ID>;
    nbPods: number;
    difficulteExerciceMemoire?: ExerciceDifficulte;  // Ne sert plus demande de Thomas on le garde pour la difficulté mémoire
    tags: Array<string>;
    type: TypeExercice;

    titre: string;
    consigne: string;
    explication: string;
    urlImage: string;
    urlImagePositionnement: string;
    language: Language;
    typeCouleurOuSon: TypeCouleurOuSon;
    typeDuree: TypeDuree;
    duree: number;
    nbTour: number;
    typeHit: TypeHit;

    // Modification pour avoir soit 0, soit aleatoire
    delayEntreAllumage: number | Array<number>;
    pauseEntreTour: number;


    timeout: number; //timeout en s en mode TypeHit=toucher

    // Uniquement pour le Type Retour A la Base
    nbAvantRetourALaBase?: number;

    // Uniquement pour le type Parcours
    enchainement?: string;
    // Ce sera pour les exo "private"
    idUser?: ID;
    idUserFirebase?: string;

    // Attention souci de retrocompatibilié. Ne doit plus être utiliser
    dossier?: string;

    public: boolean; // Indique si on souhaite rendre l'exercice public
    valide: boolean; // Prévision pour la vlaidation par le back-office avant la publicaiton des exos publics
    created?: Timestamp;
    // Pour recréer le positionnement du POD
    positionnement: Positionnement;

    // Uniquement pour le type course
    ordreCourse?: Array<number>;
    distances?: Array<number>;
    dureeCourse?: number;

    // Uniquement pour le type MultiJoueur
    sousTypeExerciceMultiJoueur: SousTypeExerciceMultiJoueur;
    players: Array<any>; // Les couleurs des joueurs
    modeCompetition: ModeCompetition;

    // Uniquement pour aléatoire
    modeDistraction: boolean;
}


export enum ModeCompetition {
    premier = 'premier',
    parallele = 'parallele'
}

export function createExercice(params: Partial<Exercice>): Exercice {
    const exo = {} as Exercice;
    Object.keys(params).forEach(attr => exo[attr] = params[attr]);
    return exo as Exercice;
}

export function createPartialParamExercice(exercice: Exercice): Partial<Exercice> {
    const exo = {
        nbTour: exercice.nbTour,
        pauseEntreTour: exercice.pauseEntreTour,
        nbPods: exercice.nbPods,
        duree: exercice.duree,
        delayEntreAllumage: exercice.delayEntreAllumage,
        timeout: exercice.timeout,
        type: exercice.type,
        typeDuree: exercice.typeDuree,
        typeHit: exercice.typeHit,
        public: exercice.public,
        modeDistraction: exercice.modeDistraction
    } as Partial<Exercice>;
    if (exo.type === TypeExercice.retourBase) {
        exo.nbAvantRetourALaBase = exercice.nbAvantRetourALaBase;
    }

    if (exo.type === TypeExercice.parcours) {
        exo.enchainement = exercice.enchainement;
    }
    if (exo.type === TypeExercice.course) {
        exo.ordreCourse = exercice.ordreCourse;
        exo.distances = exercice.distances;
        exo.dureeCourse = exercice.dureeCourse;
    }

    if (exo.type === TypeExercice.multijoueurs) {
        exo.sousTypeExerciceMultiJoueur = exercice.sousTypeExerciceMultiJoueur;
        exo.players = exercice.players;
        exo.modeCompetition = exercice.modeCompetition;
    }

    // Nettoyage des undefined
    Object.keys(exo).forEach(attr => {
        if (typeof exo[attr] === 'undefined') {
            delete exo[attr];
        }
    });
    return exo;
}


export interface ResultatExercice {
    tour: number;
    pod: Pod;
    couleur: ColorPod;
    couleurHexa: string;
    temps: number;
    hauteur: number;
    player?: number; // uniquement pour le mode multi-joueur
}

export interface ResultatExerciceMemoire {
    tour: number;
    temps: number;
    nbTouche: number;
    nbRotation: number;
}

export interface SummaryResultatExercice {
    podTouche: number;
    podEchec: number;
    podTotal: number;
    moyenneHit: number;
    dureeTotal: number;
    pourcentage: number;
    color: string;
}

// Figure pour l'image de positionnement du PODS
export interface Figure {
    type: 'circle' | 'square' | 'hexagon';
    x: number;
    y: number;
    scale: number;
    rotate: number;
    dragging: boolean;
}

export interface Positionnement {
    originalScreen?: { width: number, height: number };
    originalDecalageY: number; // Decalage de la zone dans l'écran de départ
    originalPanelHeight: number;
    originalPanelWidth: number;
    originalPodWidth: number;
    originalPodHeight: number;
    typePositionnement: TypePositionnement;
    figures: Array<Figure>;
    colors: Array<string>;
    imagesPositionnement: Array<ImagePositionnement>;
    sonsPositionnement: Array<SonPositionnement>;
    positions: Array<Position>;

    // Pour la distraction
    colorsDistraction: Array<string>;
    imagesDistraction: Array<number>; // Numero de l'image
}


export interface ImagePositionnement {
    // Tableau de numero d'image
    images: Array<number>;
}

export interface SonPositionnement {
    // Tableau de numero de son
    sons: Array<number>;
}

export interface Position {
    x: number;
    y: number;
}

export interface Ligne {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    index1: number;
    index2: number;
}

export interface Disque {
    x: number;
    y: number;
    index1: number;
    index2: number;
    r: number;
    distance: number;
}
