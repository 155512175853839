import {AfterViewInit, ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';


import {NavigationEnd, Router} from '@angular/router';

import {filter} from 'rxjs/operators';
import {environment} from '@environment/environment';

import {AuthentificationService} from '@services/auth.service';
import {NGXLogger} from 'ngx-logger';
import {Role, User} from '@services/entity/user';
import {NotificationService} from '@services/notification.service';
import {AppCheck, getToken} from '@angular/fire/app-check';
import {TransitionService} from '@services/transition.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    appCheck = inject(AppCheck);
    mobileQuery: MediaQueryList;
    enumRole = Role;
    menuEnCours: string;
    version: string;
    user: User = null;
    env: string;
    private _mobileQueryListener: () => void;
    private autoLogoutSubscription: Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private media: MediaMatcher,
                private router: Router,
                private transitionService: TransitionService,
                private authService: AuthentificationService,
                private logger: NGXLogger,
                private notificationService: NotificationService) {
        this.version = environment.versions.app;
        this.env = environment.envName;
        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();

        this.authService.connecte$.pipe(filter(ok => ok)).subscribe(ok => {
            this.user = this.authService.userConnecte;
            const infoStr = this.env + ' - ' + this.version;


            setTimeout(() => {
                console.log('APP CHECK sur ' + this.appCheck.app.name);
                getToken(this.appCheck, true).then(rep => {
                    console.log('TOKEN APPCHECK = ' + rep);
                    this.logger.log('APP BackOffice - Login ' + this.user.nom + ' - ' + this.user.prenom + ' (' + this.user.email + ') - INFO=' + infoStr);
                }, err => {
                    console.log('TOKEN APPCHECK ERROR ' + err);
                });
            }, 1000);
        });
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                // You only receive NavigationStart events
                this.menuEnCours = event.url;
            });
    }

    logout() {
        this.authService.logout().then(rep => {
            this.router.navigate(['/authentification']);
        }, err => {
            this.notificationService.openSnackBarErreur('Souci de déconnexion');
        });
    }

    ngOnInit(): void {


    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }
}
