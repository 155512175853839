import {Injectable} from '@angular/core';
import {EntityStore, StoreConfig} from '@datorama/akita';
import {PodSimulator} from './pod-simulator.model';
import {CollectionState} from 'akita-ng-fire';

export type PodSimulatorState = CollectionState<PodSimulator>

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'pod-simulator',
    idKey: 'id',
    resettable: true
})
export class PodSimulatorStore extends EntityStore<PodSimulatorState> {

    constructor() {
        super();
    }

}
