import {Injectable} from '@angular/core';
import {CognitifState, CognitifStore} from './cognitif.store';
import {CollectionConfig, CollectionService} from 'akita-ng-fire';
import {TransitionService} from '@services/transition.service';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'cognitifs' })
export class CognitifService extends CollectionService<CognitifState> {

    constructor(private cognitifStore: CognitifStore, private transitionService: TransitionService) {
        super(cognitifStore,);
    }

    /*
        get path() {
            const idUser = this.transitionService.getIdUser();
            return 'users/' + idUser + '/cognitifs';

        }
    */
}
