import * as firestore from 'firebase/firestore';
import Timestamp = firestore.Timestamp;

export function matchValue(obj: any, filter: string): boolean {
    if (obj === null || typeof obj === 'undefined') {
        return false;
    }
    filter = filter.toLowerCase();
    const keys = Object.keys(obj);
    let trouve = false;
    for (let cpt = 0; cpt < keys.length && !trouve; cpt++) {
        const key = keys[cpt];
        if (key === 'id') {
            continue;
        }
        let objstr = obj[key]?.toString();
        if (objstr !== null && typeof objstr !== 'undefined') {
            if (objstr === '[object Object]') {
                const obj1 = obj[key];
                if (matchValue(obj1, filter)) {
                    console.log('MATCH N2 = ' + obj1 + ' ' + filter);
                    trouve = true;
                }
            } else {
                objstr = objstr.toLowerCase();
                if (objstr.includes(filter)) {
                    trouve = true;
                }
            }
        }
    }
    return trouve;
}


export function deepCopy<T>(obj): T {
    let copy;
    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    // Handle Date
    if (obj instanceof Timestamp) {
        copy = new Timestamp(obj.seconds, obj.nanoseconds);
        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (const attr in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function camelCase(str: string): string {
    if (str.length <= 1) {
        return str;
    }
    return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
}
