import {Injectable} from '@angular/core';
import {LogState, LogStore} from './log.store';
import {CollectionConfig, CollectionService} from 'akita-ng-fire';
import {collection, getDocs, query, where} from '@angular/fire/firestore';
import {firstValueFrom, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Log} from '@services/entity/log/log.model';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'logs' })
export class LogService extends CollectionService<LogState> {

    constructor(protected store: LogStore) {
        super(store);
    }

    getAllLogs(): Promise<Array<Log>> {
        const logsCollection = collection(this.db, 'logs');
        const req = query(logsCollection, where('message.stack', '!=', null));
        return firstValueFrom(from(getDocs(req)).pipe(map(snap => {
            return snap.docs.map(doc => {
                const log = doc.data() as Log;
                log.id = doc.id;
                return log;
            });
        })));
    }
}
