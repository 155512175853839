import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Pathologie} from './pathologie.model';

export interface PathologieState extends EntityState<Pathologie> {
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'pathologie',
    idKey: 'id',
    resettable: true
})
export class PathologieStore extends EntityStore<PathologieState> {

    constructor() {
        super();
    }

}
