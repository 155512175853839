import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard as AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';

import {RoleGuard} from './core/guards/role.guard';
import {Role} from '@services/entity/user';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);


const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'exercices',
        loadChildren: () => import('./pages/exercices/exercices.module').then(m => m.ExercicesModule),
        canActivate: [AngularFireAuthGuard, RoleGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, canGoBack: true, roles: [Role.administrateur] },
    },
    {
        path: 'demo-jeu',
        loadChildren: () => import('./pages/demo-jeu/demo-jeu.module').then(m => m.DemoJeuModule),
        canActivate: [AngularFireAuthGuard, RoleGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, canGoBack: true, roles: [Role.testeur] },
    },
    {
        path: 'administration',
        loadChildren: () => import('./pages/administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [AngularFireAuthGuard, RoleGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin, canGoBack: true, roles: [Role.administrateur] },
    },
    {
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
